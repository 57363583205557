import Vue from 'vue'
import App from './App.vue'
import Predictions from './components/PredictionsPage.vue'

Vue.config.productionTip = false

new Vue({
  data: {
    main: Predictions
  },
  render: h => h(App)
}).$mount('#app')
