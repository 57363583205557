<template>
  <div id="app">
    <component :is="component" />
    <footer>
      <p><span>Prediction brought to you by</span> <a class="alabastro" href="https://twitter.com/freddyalabastro" target="_blank">Alabastro @FreddyAlabastro</a> <span>(design & artwork)</span></p>
      <p class="space">&nbsp;</p>
      <p><span>and</span> <a class="binarmorker" href="https://twitter.com/binarmorker" target="_blank">Binarmorker @Binarmorker</a> <span>(website & coding)</span></p>
    </footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      component: this.$root.main
    }
  }
}
</script>

<style>
  @font-face {
    font-family: 'Neue Haas Grotesk';
    src: url('~@/assets/NeueHaasDisplayRoman.ttf');
  }

  @font-face {
    font-family: 'Neue Haas Grotesk Light';
    src: url('~@/assets/NeueHaasDisplayLight.ttf');
  }

  @font-face {
    font-family: 'Neue Haas Grotesk Black';
    src: url('~@/assets/NeueHaasDisplayBlack.ttf');
  }

  :root {
    --outside: rgb(0, 0, 0);
    --button: rgba(0, 0, 0, 0.8);
    --button-hover: rgba(255, 255, 255, 0.2);
    --button-checked: rgba(255, 255, 255, 0.4);
    --title: rgb(41, 41, 41);
    --background: rgb(19, 19, 19);
    --text: rgb(255, 255, 255);
    --padding: 60px;
    --alabastro-gradient: linear-gradient(45deg, rgb(164, 74, 255), rgb(0, 240, 255)) rgb(164, 74, 255);
    --binarmorker-gradient: linear-gradient(45deg, rgb(255, 57, 137), rgb(255, 118, 26)) rgb(255, 57, 137);
    --bad-answer: rgba(255, 0, 0, 0.4);
    --good-answer: rgba(0, 255, 0, 0.4);
  }

  html, body {
    padding: 0;
    margin: 0;
    background: var(--outside);
    color: var(--text);
    font-family: 'Neue Haas Grotesk';
  }

  footer {
    background: linear-gradient(180deg, var(--background) 10%, rgba(0,0,0,0.75) 75%),
                url('~@/assets/Cover_Art.png') center 85% no-repeat;
    background-size: cover;
    width: 100%;
    height: 142px;
    box-sizing: border-box;
    padding-top: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 17px;
    text-align: center;
    padding: 0 var(--padding);
  }

  footer a {
    padding: 0 16px;
    position: relative;
    color: #ffffff;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }

  footer a:hover:before {
    content: '';
    position: absolute;
    top: -4px;
    bottom: -4px;
    left: 8px;
    right: 8px;
    background: var(--button-hover);
    opacity: 0.8;
  }

  footer a:hover:after {
    content: '';
    position: absolute;
    top: -8px;
    bottom: -8px;
    left: 4px;
    right: 4px;
    cursor: pointer;
    border: 2px solid var(--button-hover);
  }

  footer a.alabastro {
    background: var(--alabastro-gradient);
    background-size: 100%;
    background-clip: text;
  }

  footer a.binarmorker {
    background: var(--binarmorker-gradient);
    background-size: 100%;
    background-clip: text;
  }

  @media screen and (max-width: 1150px) {
    footer {
      flex-direction: column;
    }

    footer {
      justify-content: center;
      padding: 0;
      padding-bottom: 16px;
    }

    footer .space {
      display: none;
    }

    footer p {
      margin: 8px 0;
    }
  }

  @media screen and (max-width: 700px) {
    footer p {
      display: flex;
      flex-direction: column;
      margin: 0;
    }
  }

  @media screen and (max-width: 600px) {
    :root {
      --padding: 30px;
    }
  }
</style>
